import React from "react"
// import { Link } from "gatsby"

import Layout from "../../components/layout"
import SEO from "../../components/seo"

import Hero from "../../components/hero/hero"
import RightCta from "../../components/cta/right-bordered/cta"
import LeftCta from "../../components/cta/left-bordered/cta"
// import Savings from "../../components/savings/savings";

import desktopHero from "../../images/getting-started/pr-bannerBG.png"
import mobileHero from "../../images/getting-started/mobileHero2.png"
import gradientOne from "../../images/getting-started/gradientOne.png"
// import gradientTwo from "../../images/getting-started/gradientTwo.png"
import ctaBackground from "../../images/getting-started/ctaBackground.svg"
import doctor from "../../images/cardiovascular-risk/1.0_750x402_Doctor_Patient_Desktop_1X.png"
import gradientThree from "../../images/clinical-trial-results/gradientThree.png"

import glassHeart from "../../images/getting-started/4.0_550x380_Glass_Heart_Desktop_1X.png"
import DDG from "../../images/getting-started/Vascepa-Downloadable-DDG-2020.png"
import manCycling from "../../images/getting-started/4.0_550x380_Excerise_Desktop_1X.png"
import nurseMan from "../../images/getting-started/4.0_750x380_Nurse_Man_Desktop_1X.png"
import patientBrochure2 from "../../images/getting-started/PB_icon_new.png"
import patientBrochure from "../../images/getting-started/patient_brochure.png"
import BlinkRxPDF from "../../images/getting-started/blinkrx-pdf.jpg"
import gradientTwo from "../../images/cardiovascular-risk/gradientTwo.svg"
import "./helpful-resources.css"

export default () => (
  <Layout>
    <SEO
      title="Find Helpful Resources Here"
      keywords="Patient Resources"
      description="Explore resources specifically designed to help you along your heart health journey. Please see Indication and Important Safety Information."
    />

    <Hero
      desktopHeroBackground={desktopHero}
      mobileHeroBackground={mobileHero}
      alt="Doctor with stethoscope smiling"
      headline={[
        "Use the resources below to help you on your journey towards better heart health",
      ]}
    />

    <div className="block row">
      <div
        style={{ padding: "0px" }}
        className="col-md-5 block-content whiteborderRight"
      >
        <div className="brochure">
          <img src={DDG} alt="Doctor Discussion Guide"></img>
        </div>
      </div>

      <div className="col-md-7 block-content resource-block normal-overflow">
        <img
          class="background-gradient"
          src={gradientThree}
          alt=""
          style={{ width: "100%", height: "100%" }}
        ></img>
        <div class="content mobile-p-b-160">
          <h3
            className="font-margins"
            style={{
              fontSize: "30px",
              lineHeight: "42px",
              fontWeight: "700",
              zIndex: "1",
              position: "relative",
              marginBottom: "10px",
            }}
          >
            Doctor discussion guide
          </h3>
          <p
            className="font-margins"
            style={{
              fontSize: "24px",
              zIndex: "1",
              position: "relative",
              marginTop: "10px",
            }}
          >
            Is VASCEPA right for you? Download and complete this guide before
            your next doctor visit to help get the conversation started.
          </p>

          <div class="links">
            <h3>Download the doctor discussion guide</h3>
            <div class="select-box">
              <div class="chevron"></div>
              <label>
                <b>Select language</b>
              </label>
              <ul>
                <li>
                  <a
                    href="https://amarincorp.com/docs/Vascepa-Downloadable-DDG-2020.pdf"
                    target="_blank"
                    className="select-option"
                  >
                    English
                  </a>
                </li>
              </ul>
            </div>
            {/* <h3>Download the doctor discussion guide</h3>
			<a>English</a>
			<a>Korean</a>
			<a>Simplified Chinese</a>
			<a>Spanish</a>
			<a>Traditional Chinese</a> */}
          </div>
        </div>
      </div>
    </div>

    <div className="block row ">
      <div className="col-md-7 block-content resource-block normal-overflow whiteborderRight">
        <img
          class="background-gradient"
          src={gradientThree}
          alt=""
          style={{ width: "100%", height: "100%" }}
        ></img>
        <div class="content mobile-p-b-160">
          <h3
            className="font-margins"
            style={{
              fontSize: "30px",
              lineHeight: "42px",
              fontWeight: "700",
              zIndex: "1",
              position: "relative",
              marginBottom: "10px",
            }}
          >
            BlinkRx patient brochure
          </h3>
          <p
            className="font-margins"
            style={{
              fontSize: "24px",
              zIndex: "1",
              position: "relative",
              marginTop: "10px",
            }}
          >
            Want to learn more about saving on VASCEPA with BlinkRx? Download
            this brochure.
          </p>
          <p
            className="font-margins"
            style={{
              fontSize: "24px",
              zIndex: "1",
              position: "relative",
              marginTop: "10px",
            }}
          >
            Exclusive low pricing and free home delivery.
          </p>

          <div class="links">
            <h3>Download the BlinkRx patient brochure</h3>
            <div class="select-box">
              <div class="chevron"></div>
              <label>
                <b>Select language</b>
              </label>
              <ul>
                <li>
                  <a
                    href="https://amarincorp.com/docs/US-VAS-03467BlinkRxPatientBrochure_English.pdf"
                    target="_blank"
                    className="select-option"
                  >
                    English
                  </a>
                </li>
                <li>
                  <a
                    href="https://amarincorp.com/docs/US-VAS-03523BlinkRxPatientBrochure_SimplifiedChinese.pdf"
                    target="_blank"
                    className="select-option"
                  >
                    Simplified Chinese
                  </a>
                </li>
                <li>
                  <a
                    href="https://amarincorp.com/docs/US-VAS-03522BlinkRxPatientBrochure_Spanish.pdf"
                    target="_blank"
                    className="select-option"
                  >
                    Spanish
                  </a>
                </li>
                <li>
                  <a
                    href="https://amarincorp.com/docs/US-VAS-03524BlinkRxPatientBrochure_TraditionalChinese.pdf"
                    target="_blank"
                    className="select-option"
                  >
                    Traditional Chinese
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div style={{ padding: "0px" }} className="col-md-5 block-content">
        <div className="brochure">
          <img src={BlinkRxPDF} alt="BlinkRx patient brochure"></img>
        </div>
      </div>
    </div>

    <div className="block row helpful-container">
      <div
        style={{ padding: "0px", minHeight: "300px" }}
        className="col-md-5 block-content whiteborderRight"
      >
        <div className="brochure">
          <img
            src={patientBrochure2}
            alt="Patient Brochure"
            className="patient-brochure-image"
          />
        </div>
      </div>
      <div className="col-md-7 block-content resource-block blue  light-BG normal-overflow">
        <div
          class="content grid-center mobile-p-b-160"
          style={{ "padding-bottom": "0" }}
        >
          <h3
            className="font-margins"
            style={{
              fontSize: "30px",
              lineHeight: "42px",
              fontWeight: "700",
              zIndex: "1",
              position: "relative",
              marginBottom: "10px",
            }}
          >
            Patient brochure
          </h3>
          <p
            className="font-margins"
            style={{
              fontSize: "24px",
              zIndex: "1",
              position: "relative",
              marginTop: "10px",
            }}
          >
            Questions about VASCEPA? Our brochure has answers.
          </p>
          <div class="links">
            <h3>Download the patient brochure</h3>
            <div class="select-box blue">
              <div class="chevron"></div>
              <label>
                <b>Select language</b>
              </label>
              <ul>
                <li>
                  <a
                    href="https://amarincorp.com/docs/VAS-02344VascepaHCCPatientBrochure_CVLaunch_R1.pdf"
                    target="_blank"
                    className="select-option"
                  >
                    English
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
)
